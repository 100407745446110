﻿import { FunctionComponent, useState } from "react";
import { SetPageTitle } from "../../components/SetPageTitle";
import { useNavigate, useParams } from "react-router-dom";
import { useCandidate } from "../../api/hooks/useCandidate";
import {
    Button,
    Badge,
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Card,
    CardBody,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    IconButton,
    Link,
    Switch,
    Td,
    Text,
    Textarea,
    Tooltip,
    Tr,
    VStack,
    Stack,
} from "@chakra-ui/react";
import { CandidateQualification, displayEmploymentStatus, displayQualification, displayZone, EntityCandidateArgs } from "../../api/models/EntityCandidate";
import { CandidateStatusIcon, getCandidateStatusTitle } from "../../components/CandidateStatus";
import { IconEdit } from "@tabler/icons-react";
import { CandidateTimeline } from "./CandidateTimeline";
import { useRequestDownloadFileFn, useRequests } from "../../api";
import { DownloadLink, formatDateStr, formatPhone, formatSsn, formatTimestampStr, Shimmer, TableResult, useToast } from "@am-tax/fe-core";
import { useUserContext } from "../../context";
import { displayRequestStatus, getStatusIcon } from "../../common";
import { CandidateQuestionnaire } from "./CandidateQuestionnaire";
import { useAreaPermissions, useBoolean } from "../../hooks";
import { CandidatesArea } from "../../auth";
import { useCandidateTimeline } from "../../api/hooks/useCandidateTimeline";
import { useCandidateSave } from "../../api/hooks/useCandidateSave";
import { useCandidateCommentCreate } from "../../api/hooks/useCandidateCommentCreate";
import { useCandidateComments } from "../../api/hooks/useCandidateComments";
import { CandidateCommentCards } from "./CandidateComments";
import { CandidateCommentProps } from "../../api/models/CandidateComment";

export const CandidatePage: FunctionComponent = () => {
    const { cId, eId, candidateId } = useParams();
    const areaPermissions = useAreaPermissions(CandidatesArea, cId);
    const navigate = useNavigate();
    const candidateQuery = useCandidate(cId, eId, candidateId);
    const candidate = candidateQuery.data;
    const candidateLoading = candidateQuery.isLoading;
    const requestsQuery = useRequests(cId, eId, candidateId);
    const requests = requestsQuery.data || null;

    const downloadFileFn = useRequestDownloadFileFn();

    const { dateFormat } = useUserContext();

    const showEligibility = true;
    const showEmployment = true;

    const [showFullSsn, fullSsn] = useBoolean(false);

    const candidateTimelineQuery = useCandidateTimeline(cId, eId, candidateId);

    // ---- CandidateComment Components  ----
    const [comment, setComment] = useState("");
    const candidateComments = useCandidateComments(cId, eId, candidateId);

    // ---- Setup Submit Handlers for saving the Form ----
    const toast = useToast();
    const saveCandidate = useCandidateSave();
    const saveNotificationPref = async (enableNotifications: boolean) => {
        const args: EntityCandidateArgs = {
            clientId: cId,
            entityId: eId,
            // @ts-ignore
            candidate: {
                ...candidate,
                preventNotificationsUntil: enableNotifications ? null : "9999-12-31T23:59:59",
            },
        };
        saveCandidate.mutate(args, {
            onSuccess: data => {
                toast({
                    title: "Saved",
                    description: "Notification preference saved",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            },
        });
    };

    //  ---- Create CandidateComment handler ----
    const saveCandidateComment = useCandidateCommentCreate();
    const createCandidateComment = async (comment: string) => {
        const props: CandidateCommentProps = {
            tenantId: cId,
            entityId: eId,
            candidateId: candidateId,
            commentBody: comment,
        };
        saveCandidateComment.mutate(props, {
            onSuccess: data => {
                setComment("");
                candidateComments.refetch();
            },
        });
    };

    function showMissing(value: string | number | null | undefined, missingText?: string) {
        return value ? <Text>{value}</Text> : <Text color={"gray.200"}>{missingText || "missing"}</Text>;
    }

    return (
        <>
            <Breadcrumb mt={"-1.5rem"} mb={"1.5rem"}>
                <BreadcrumbItem>
                    <BreadcrumbLink
                        onClick={() => {
                            navigate(`/clients/${cId}/candidates`);
                        }}
                    >
                        Candidates
                    </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink>
                        <Shimmer isLoading={candidateLoading}>
                            {candidate?.firstName} {candidate?.lastName}
                        </Shimmer>
                    </BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>

            <Flex direction={"column"} gap={"2rem"}>
                <SetPageTitle title={"Candidate Details"} />

                <Card variant={"withShadow"}>
                    <CardBody p={"2rem"}>
                        <Grid gridTemplateColumns={"auto 1fr auto 1fr"} columnGap={"1rem"} rowGap={".25rem"} alignItems={"center"}>
                            <GridItem colSpan={3}>
                                <HStack gap={"3rem"}>
                                    <Shimmer isLoading={candidateLoading}>
                                        <Heading variant={"cardHeading"}>
                                            {candidate?.firstName} {candidate?.middleName} {candidate?.lastName}
                                        </Heading>
                                    </Shimmer>
                                    <Shimmer isLoading={candidateLoading}>
                                        <HStack>
                                            <CandidateStatusIcon status={candidate?.status} boxSize={"1.5rem"} />
                                            <Box>{getCandidateStatusTitle(candidate?.status)}</Box>
                                        </HStack>
                                    </Shimmer>
                                </HStack>
                            </GridItem>
                            <GridItem justifySelf={"end"}>
                                {areaPermissions.has(CandidatesArea.edit) && (
                                    <IconButton icon={<Icon as={IconEdit} />} aria-label={"Edit User Details"} onClick={() => navigate("details")} />
                                )}
                            </GridItem>

                            {areaPermissions.hasAny(CandidatesArea.read_basic, CandidatesArea.read_full) && (
                                <>
                                    <GridItem colSpan={2} rowSpan={3}>
                                        <VStack align={"start"}>
                                            <Box>
                                                {candidate?.street1 ? (
                                                    <>
                                                        <Shimmer isLoading={candidateLoading}>
                                                            <Text>{candidate?.street1}</Text>
                                                        </Shimmer>
                                                        {candidate?.street2 && <Text>{candidate?.street2}</Text>}
                                                        <Shimmer isLoading={candidateLoading}>
                                                            <Text>
                                                                {candidate?.city}, {candidate?.state} {candidate?.zipCode}
                                                            </Text>
                                                        </Shimmer>
                                                    </>
                                                ) : (
                                                    showMissing(candidate?.street1, "address missing")
                                                )}
                                            </Box>
                                        </VStack>
                                    </GridItem>

                                    <GridItem>
                                        <Text variant={"label"}>Email:</Text>
                                    </GridItem>
                                    <GridItem>
                                        <Shimmer isLoading={candidateLoading}>{showMissing(candidate?.email)}</Shimmer>
                                    </GridItem>
                                    <GridItem>
                                        <Text variant={"label"}>Phone:</Text>
                                    </GridItem>
                                    <GridItem>
                                        <Shimmer isLoading={candidateLoading}>{showMissing(formatPhone(candidate?.phone))}</Shimmer>
                                    </GridItem>
                                    <GridItem>
                                        <Text variant={"label"}>Birth:</Text>
                                    </GridItem>
                                    <GridItem>
                                        <Shimmer isLoading={candidateLoading}>{showMissing(formatDateStr(candidate?.dateOfBirth, dateFormat))}</Shimmer>
                                    </GridItem>
                                    <GridItem>
                                        <Text variant={"label"}>County:</Text>
                                    </GridItem>
                                    <GridItem>
                                        <Shimmer isLoading={candidateLoading}>{showMissing(candidate?.county)}</Shimmer>
                                    </GridItem>
                                    <GridItem>
                                        <Text variant={"label"}>
                                            SSN (<Link onClick={fullSsn.toggle}>{showFullSsn ? "hide" : "show"} full</Link>):
                                        </Text>
                                    </GridItem>
                                    <GridItem>
                                        <Shimmer isLoading={candidateLoading}>
                                            <pre>
                                                {showMissing(
                                                    candidate?.socialSecurityNumber && candidate?.socialSecurityNumber?.length === 9
                                                        ? showFullSsn
                                                            ? formatSsn(candidate?.socialSecurityNumber)
                                                            : "***-**-" + candidate?.socialSecurityNumber.substring(5)
                                                        : undefined
                                                )}
                                            </pre>
                                        </Shimmer>
                                    </GridItem>
                                    <GridItem colSpan={4}>
                                        {candidate?.designatedZoneType && (
                                            <div>
                                                <Tooltip label={candidate.designatedZone}>
                                                    <Badge colorScheme={"green"}>{displayZone(candidate.designatedZoneType)}</Badge>
                                                </Tooltip>
                                                <br />
                                                {candidate.designatedZone}
                                            </div>
                                        )}
                                    </GridItem>

                                    <GridItem colSpan={4}>
                                        <Divider my={"1rem"} />
                                    </GridItem>
                                </>
                            )}
                            {showEligibility && areaPermissions.hasAny(CandidatesArea.read_basic, CandidatesArea.read_full) && (
                                <>
                                    <GridItem colSpan={4}>
                                        <Heading variant={"info"} mb={".5rem"}>
                                            Eligibility
                                        </Heading>
                                    </GridItem>
                                    <GridItem>
                                        <Text variant={"label"}>Qualifications:</Text>
                                    </GridItem>
                                    <GridItem>
                                        <Shimmer isLoading={candidateLoading}>
                                            {areaPermissions.has(CandidatesArea.read_full)
                                                ? candidate?.qualifications?.map(q => displayQualification(q as CandidateQualification)).join(", ")
                                                : showMissing(null, "withheld")}
                                        </Shimmer>
                                    </GridItem>
                                    <GridItem>
                                        <Text variant={"label"}>Days Remaining:</Text>
                                    </GridItem>
                                    <GridItem>
                                        {candidate && candidate.daysRemaining >= 0 && candidate.daysRemaining !== null && (
                                            <Badge
                                                colorScheme={candidate?.daysRemaining > 21 ? "green" : candidate?.daysRemaining > 14 ? "yellow" : "red"}
                                                variant={"solid"}
                                                title={formatDateStr(candidate?.targetDate, dateFormat)}
                                            >
                                                {candidate?.daysRemaining}
                                            </Badge>
                                        )}
                                    </GridItem>

                                    <GridItem>
                                        <Text variant={"label"}>Target Qualification:</Text>
                                    </GridItem>
                                    <GridItem colSpan={3}>
                                        <Shimmer isLoading={candidateLoading}>
                                            {areaPermissions.has(CandidatesArea.read_full) && (
                                                <HStack>
                                                    {showMissing(
                                                        candidate?.selectedQualification &&
                                                            displayQualification(candidate?.selectedQualification as CandidateQualification),
                                                        "Not Selected"
                                                    )}
                                                    {!candidate?.selectedQualification && <Badge colorScheme={"red"}>Action Needed</Badge>}
                                                </HStack>
                                            )}
                                        </Shimmer>
                                    </GridItem>

                                    <GridItem colSpan={4}>
                                        <Divider my={"1rem"} />
                                    </GridItem>
                                </>
                            )}

                            {showEmployment && areaPermissions.has(CandidatesArea.read_full) && (
                                <>
                                    <GridItem colSpan={4}>
                                        <Heading variant={"info"} mb={".5rem"}>
                                            Employment
                                        </Heading>
                                    </GridItem>
                                    <GridItem>
                                        <Text variant={"label"}>Status:</Text>
                                    </GridItem>
                                    <GridItem>{candidate?.employmentStatus ? displayEmploymentStatus(candidate.employmentStatus) : ""}</GridItem>
                                    <GridItem>
                                        <Text variant={"label"}>Hours Worked:</Text>
                                    </GridItem>
                                    <GridItem>{candidate?.hoursWorked}</GridItem>
                                    <GridItem>
                                        <Text variant={"label"}>Hire Date:</Text>
                                    </GridItem>
                                    <GridItem>{formatDateStr(candidate?.hireDate, dateFormat)}</GridItem>
                                    <GridItem>
                                        <Text variant={"label"}>Hourly Rate:</Text>
                                    </GridItem>
                                    <GridItem>{candidate?.hourlyRate && `$${candidate?.hourlyRate}`}</GridItem>
                                    <GridItem>
                                        <Text variant={"label"}>Start Date:</Text>
                                    </GridItem>
                                    <GridItem>{formatDateStr(candidate?.startDate, dateFormat)}</GridItem>
                                    <GridItem>
                                        <Text variant={"label"}>Total Wages:</Text>
                                    </GridItem>
                                    <GridItem>
                                        {candidate?.hourlyRate && candidate?.hoursWorked && `$${(candidate?.hourlyRate * candidate?.hoursWorked).toFixed(2)}`}
                                    </GridItem>
                                    <GridItem>
                                        <Text variant={"label"}>Offer Date:</Text>
                                    </GridItem>
                                    <GridItem>{formatDateStr(candidate?.offerDate, dateFormat)}</GridItem>
                                    <GridItem></GridItem>
                                    <GridItem></GridItem>
                                    <GridItem>
                                        <Text variant={"label"}>Termination Date:</Text>
                                    </GridItem>
                                    <GridItem>{formatDateStr(candidate?.terminationDate, dateFormat)}</GridItem>
                                    <GridItem></GridItem>
                                    <GridItem></GridItem>
                                    <GridItem>
                                        <Text variant={"label"}>Questionnaire Completion Date:</Text>
                                    </GridItem>
                                    <GridItem>{formatDateStr(candidate?.questionnaireCompletedOn, dateFormat)}</GridItem>
                                </>
                            )}
                        </Grid>
                    </CardBody>
                </Card>

                <Card variant={"withShadow"}>
                    <CardBody p={"2rem"}>
                        <form>
                            <Flex gap={"1rem"} pb={".5rem"} alignItems={"center"} justifyContent={"space-between"}>
                                <Heading mb={0} variant={"cardHeading"}>
                                    Comments
                                </Heading>
                            </Flex>
                            <Stack spacing={".5rem"}>
                                {candidateComments.data?.map(comment => (
                                    <CandidateCommentCards key={comment.id} {...comment}></CandidateCommentCards>
                                ))}
                            </Stack>
                            <Divider my={"1rem"} />
                            <Stack>
                                <Textarea placeholder="Add a comment." size="md" resize="none" value={comment} onChange={e => setComment(e.target.value)} />
                            </Stack>

                            <Flex justifyContent="flex-end" mt={"2rem"} width={"full"}>
                                <Button
                                    variant={"primary"}
                                    mr={3}
                                    onClick={() => createCandidateComment(comment)}
                                    isDisabled={!comment}
                                    isLoading={saveCandidateComment.isLoading}
                                    loadingText={"Creating"}
                                >
                                    Post
                                </Button>
                                <Button variant="ghost" onClick={() => setComment("")}>
                                    Cancel
                                </Button>
                            </Flex>
                        </form>
                    </CardBody>
                </Card>

                {areaPermissions.has(CandidatesArea.request_mgmt) && (
                    <Card variant={"withShadow"}>
                        <CardBody p={"2rem"}>
                            <Flex gap={"1rem"} pb={".5rem"} alignItems={"center"} justifyContent={"space-between"}>
                                <Heading mb={0} variant={"cardHeading"}>
                                    Requested Documents
                                </Heading>
                                <VStack align={"end"}>
                                    <Box>
                                        <form onSubmit={e => e.preventDefault()}>
                                            <FormControl display="flex" gap={"1rem"}>
                                                <VStack align={"end"} spacing={0}>
                                                    <FormLabel htmlFor="email-alerts" m="0" mt={"2px"}>
                                                        Enable Notifications
                                                    </FormLabel>
                                                    {candidate?.preventNotificationsUntil && (
                                                        <Text variant={"info"}>
                                                            Notifications disabled until
                                                            {formatTimestampStr(candidate?.preventNotificationsUntil, dateFormat, true, false)}
                                                        </Text>
                                                    )}
                                                </VStack>
                                                <Switch
                                                    id="email-alerts"
                                                    size={"lg"}
                                                    colorScheme={"green"}
                                                    onChange={e => {
                                                        saveNotificationPref(e.target.checked);
                                                    }}
                                                />
                                            </FormControl>
                                        </form>
                                    </Box>
                                </VStack>
                            </Flex>
                            <TableResult query={requestsQuery} columns={["Request", "Status", "Files", "Edit"]}>
                                {candidateId &&
                                    requests?.map(request => (
                                        <Tr key={request.id}>
                                            <Td>
                                                <HStack align={"start"}>
                                                    <Box alignSelf={"start"} pt={"1px"}>
                                                        {getStatusIcon(request.status)}
                                                    </Box>
                                                    <Box>
                                                        <Text color={"blackAlpha.800"} variant={"label"}>
                                                            {request.preferred}
                                                        </Text>
                                                        {request.documents?.map(document => (
                                                            <HStack key={document.id} ml={"1rem"}>
                                                                <DownloadLink
                                                                    downloadFn={async progressCallback => {
                                                                        const blob = await downloadFileFn(
                                                                            cId,
                                                                            eId,
                                                                            candidateId!,
                                                                            request.id,
                                                                            document.id,
                                                                            progressCallback
                                                                        );
                                                                        return { blob, fileName: `${document.name}` };
                                                                    }}
                                                                >
                                                                    {document.name}
                                                                </DownloadLink>
                                                            </HStack>
                                                        ))}
                                                    </Box>
                                                </HStack>
                                            </Td>
                                            <Td>{displayRequestStatus(request.status)}</Td>
                                            <Td>{request.documents?.length ? "Uploaded" : <Badge colorScheme={"red"}>Missing</Badge>}</Td>
                                            <Td>
                                                <IconButton
                                                    icon={<Icon as={IconEdit} />}
                                                    aria-label={"Edit Request"}
                                                    onClick={() => navigate(`request/${request.id}`)}
                                                />
                                            </Td>
                                        </Tr>
                                    ))}
                            </TableResult>
                        </CardBody>
                    </Card>
                )}

                {areaPermissions.hasAny(CandidatesArea.read_basic, CandidatesArea.read_full) && (
                    <Card variant={"withShadow"}>
                        <CardBody p={"2rem"}>
                            <Flex direction={"column"} gap={"1rem"}>
                                <Heading variant={"cardHeading"}>Timeline</Heading>
                                <Shimmer isLoading={candidateTimelineQuery.isLoading}>
                                    <CandidateTimeline candidateActivity={candidateTimelineQuery.data} />
                                </Shimmer>
                            </Flex>
                        </CardBody>
                    </Card>
                )}

                {areaPermissions.has(CandidatesArea.questionnaire) && <CandidateQuestionnaire />}
            </Flex>
        </>
    );
};
