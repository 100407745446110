import { Button, Card, CardBody, Divider, Flex, FormControl, FormErrorMessage, FormLabel, Heading, HStack, Input, Select, VStack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import {
    DatePicker,
    MaskedPhoneInputControlled,
    MaskedSsnInputControlled,
    MaskedZipCodeInputControlled,
    RepeatShimmer,
    Shimmer,
    SingleSelectControlled,
    useToast,
} from "@am-tax/fe-core";
import { useCandidate } from "../../api/hooks/useCandidate";
import {
    CandidateQualification,
    CandidateStatus,
    displayEmploymentStatus,
    displayQualification,
    EmploymentStatus,
    EntityCandidate,
    EntityCandidateArgs,
} from "../../api/models/EntityCandidate";
import { useCandidateSave } from "../../api/hooks/useCandidateSave";
import { getCandidateStatusTitle } from "../../components/CandidateStatus";
import { StateResponse, useStates } from "../../api";
import { FunctionComponent } from "react";
import { QualificationCheckboxes } from "./QualificationCheckboxes";

type FormValues = Partial<EntityCandidate>;

interface CandidateDetailsFormProps {
    returnTo: string;
}

export const CandidateDetailsForm: FunctionComponent<CandidateDetailsFormProps> = ({ returnTo }) => {
    const navigate = useNavigate();
    const toast = useToast();
    const { cId, eId, candidateId } = useParams();
    const candidateQuery = useCandidate(cId, eId, candidateId);
    const candidate = candidateQuery.data;
    const statesQuery = useStates();

    // ---- Code to Load the Form ----
    const formValues: FormValues = {
        ...candidate,
    };
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<FormValues>({
        values: formValues,
    });

    // ---- Setup Submit Handlers for saving the Form ----
    const saveCandidate = useCandidateSave();
    const onSubmit: SubmitHandler<FormValues> = async data => {
        const args: EntityCandidateArgs = {
            clientId: cId,
            entityId: eId,
            // @ts-ignore
            candidate: {
                ...candidate,
                ...data,
            },
        };
        saveCandidate.mutate(args, {
            onSuccess: data => {
                toast({
                    title: "Saved",
                    description: "Request Saved.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                goBackToCandidate();
            },
        });
    };
    const formSubmit = handleSubmit(onSubmit);

    // ---- Navigation Helper ----
    const goBackToCandidate = () => {
        navigate(`/clients/${cId}/candidates/${eId}/${candidateId}/${returnTo}`);
    };

    return (
        <>
            <VStack hidden={!candidateQuery.isLoading} spacing="1px" alignItems="stretch">
                <RepeatShimmer times={5} height="40px" />
            </VStack>

            {!candidateQuery.isLoading && (
                <form onSubmit={formSubmit}>
                    <fieldset disabled={saveCandidate.isLoading}>
                        <VStack gap={"1rem"} alignItems={"start"}>
                            <Flex gap={"1rem"} width={"100%"} alignItems={"end"}>
                                <FormControl isInvalid={!!errors?.firstName}>
                                    <FormLabel>Name:</FormLabel>
                                    <Input placeholder="First" {...register("firstName", { required: "First Name is required" })} />
                                    <FormErrorMessage>{errors?.firstName?.message}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={!!errors?.middleName}>
                                    <Input placeholder="Middle" {...register("middleName")} />
                                    <FormErrorMessage>{errors?.middleName?.message}</FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <FormControl isInvalid={!!errors?.lastName}>
                                <Input placeholder={"Last"} {...register("lastName", { required: "Last Name is required" })} />
                                <FormErrorMessage>{errors?.lastName?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!errors?.status}>
                                <FormLabel>Status:</FormLabel>
                                <Select placeholder={"Select a Status"} {...register("status", { required: "Status is required." })}>
                                    {Object.values(CandidateStatus).map(status => (
                                        <option key={status} value={status}>
                                            {getCandidateStatusTitle(status)}
                                        </option>
                                    ))}
                                </Select>
                                <FormErrorMessage>{errors?.status?.message}</FormErrorMessage>
                            </FormControl>
                            <Divider mt={"1rem"} />
                            <HStack gap="2rem" width={"100%"} alignItems={"stretch"}>
                                <VStack gap="1rem" width={"100%"} alignItems={"stretch"}>
                                    <Heading variant={"info"}>Address</Heading>
                                    <FormControl isInvalid={!!errors?.street1}>
                                        <FormLabel>Street 1:</FormLabel>
                                        <Input placeholder={"1 Main Street"} {...register("street1")} />
                                        <FormErrorMessage>{errors?.street1?.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors?.street2}>
                                        <FormLabel>Street 2:</FormLabel>
                                        <Input placeholder={"Street 2"} {...register("street2")} />
                                        <FormErrorMessage>{errors?.street2?.message}</FormErrorMessage>
                                    </FormControl>
                                    <HStack>
                                        <FormControl isInvalid={!!errors?.city}>
                                            <FormLabel>City:</FormLabel>
                                            <Input placeholder="City" {...register("city")} />
                                            <FormErrorMessage>{errors?.city?.message}</FormErrorMessage>
                                        </FormControl>
                                        <FormControl isInvalid={!!errors?.county}>
                                            <FormLabel>County:</FormLabel>
                                            <Input placeholder="County" {...register("county")} />
                                            <FormErrorMessage>{errors?.county?.message}</FormErrorMessage>
                                        </FormControl>
                                    </HStack>
                                    <HStack>
                                        <FormControl isInvalid={!!errors?.zipCode}>
                                            <FormLabel>Zip Code:</FormLabel>
                                            <MaskedZipCodeInputControlled control={control} name="zipCode" invalidMessage={"Zip code is invalid"} />
                                            <FormErrorMessage>{errors?.zipCode?.message}</FormErrorMessage>
                                        </FormControl>
                                        <FormControl isInvalid={!!errors?.state}>
                                            <FormLabel>State:</FormLabel>
                                            <Shimmer isLoading={statesQuery.isLoading}>
                                                <SingleSelectControlled
                                                    control={control}
                                                    name="state"
                                                    placeholder={"State"}
                                                    options={statesQuery.data?.map((state: StateResponse) => ({
                                                        label: state.name,
                                                        value: state.code,
                                                    }))}
                                                />
                                            </Shimmer>
                                            <FormErrorMessage>{errors?.state?.message}</FormErrorMessage>
                                        </FormControl>
                                    </HStack>
                                </VStack>
                                <VStack gap="1rem" width={"100%"} alignItems={"stretch"}>
                                    <Heading variant={"info"}>Personal Info</Heading>
                                    <FormControl isInvalid={!!errors?.phone}>
                                        <FormLabel>Phone:</FormLabel>
                                        <MaskedPhoneInputControlled control={control} name="phone" invalidMessage={"Phone Number is invalid"} />
                                        <FormErrorMessage>{errors?.phone?.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors?.email}>
                                        <FormLabel>Email:</FormLabel>
                                        <Input placeholder="Email" type={"email"} {...register("email")} />
                                        <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors?.socialSecurityNumber}>
                                        <FormLabel>Social Security Number:</FormLabel>
                                        <MaskedSsnInputControlled
                                            control={control}
                                            name="socialSecurityNumber"
                                            invalidMessage={"Social Security Number is invalid"}
                                        />
                                        <FormErrorMessage>{errors?.socialSecurityNumber?.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors?.dateOfBirth}>
                                        <FormLabel>Date of Birth:</FormLabel>
                                        <DatePicker {...register("dateOfBirth")} />
                                        <FormErrorMessage>{errors?.dateOfBirth?.message}</FormErrorMessage>
                                    </FormControl>
                                </VStack>
                            </HStack>
                            <Divider mt={"1rem"} />
                            <Heading variant={"info"}>Eligibility</Heading>
                            <FormControl isInvalid={!!errors?.qualifications}>
                                <FormLabel>Target Qualification:</FormLabel>
                                <Select placeholder={"Select a Qualification"} {...register("selectedQualification")}>
                                    {Object.values(CandidateQualification).map((qualification: CandidateQualification) => (
                                        <option key={qualification} value={qualification}>
                                            {displayQualification(qualification)}
                                        </option>
                                    ))}
                                </Select>
                                <FormErrorMessage>{errors?.qualifications?.message}</FormErrorMessage>
                            </FormControl>
                            <Card width={"100%"}>
                                <CardBody>
                                    <VStack spacing=".5em" justifyContent="left" alignItems={"stretch"}>
                                        <QualificationCheckboxes clientId={cId} candidateId={candidateId} entityId={eId} tenantId={cId} />
                                    </VStack>
                                </CardBody>
                            </Card>
                            <Divider mt={"1rem"} />
                            <Heading variant={"info"}>Employment</Heading>
                            <FormControl isInvalid={!!errors?.employmentStatus}>
                                <FormLabel>Employment Status:</FormLabel>
                                <SingleSelectControlled
                                    control={control}
                                    name="employmentStatus"
                                    placeholder={"Choose Employment Status"}
                                    options={Object.values(EmploymentStatus).map((status: EmploymentStatus) => ({
                                        label: displayEmploymentStatus(status),
                                        value: status,
                                    }))}
                                />
                                <FormErrorMessage>{errors?.employmentStatus?.message}</FormErrorMessage>
                            </FormControl>
                            <HStack gap="2rem" width={"100%"} alignItems={"stretch"}>
                                <VStack gap="1rem" width={"100%"} alignItems={"stretch"}>
                                    <FormControl isInvalid={!!errors?.questionnaireCompletedOn}>
                                        <FormLabel>Questionnaire Completion Date:</FormLabel>
                                        <DatePicker {...register("questionnaireCompletedOn")} />
                                        <FormErrorMessage>{errors?.questionnaireCompletedOn?.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors?.hireDate}>
                                        <FormLabel>Hire Date:</FormLabel>
                                        <DatePicker {...register("hireDate")} />
                                        <FormErrorMessage>{errors?.hireDate?.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors?.terminationDate}>
                                        <FormLabel>Termination Date:</FormLabel>
                                        <DatePicker {...register("terminationDate")} />
                                        <FormErrorMessage>{errors?.terminationDate?.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors?.offerDate}>
                                        <FormLabel>Offer Date:</FormLabel>
                                        <DatePicker {...register("offerDate")} />
                                        <FormErrorMessage>{errors?.offerDate?.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors?.startDate}>
                                        <FormLabel>Start Date:</FormLabel>
                                        <DatePicker {...register("startDate")} />
                                        <FormErrorMessage>{errors?.startDate?.message}</FormErrorMessage>
                                    </FormControl>
                                </VStack>
                                <VStack gap="1rem" width={"100%"} alignItems={"stretch"}>
                                    <FormControl isInvalid={!!errors?.hourlyRate}>
                                        <FormLabel>Hourly Rate:</FormLabel>
                                        <Input type={"number"} {...register("hourlyRate")} step={".01"} />
                                        <FormErrorMessage>{errors?.hourlyRate?.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors?.hoursWorked}>
                                        <FormLabel>Hours Worked:</FormLabel>
                                        <Input type={"number"} {...register("hoursWorked")} step={".01"} />
                                        <FormErrorMessage>{errors?.hoursWorked?.message}</FormErrorMessage>
                                    </FormControl>
                                </VStack>
                            </HStack>
                            <Flex justifyContent="flex-end" mt={"2rem"} width={"full"}>
                                <Button
                                    type="submit"
                                    variant={"primary"}
                                    mr={3}
                                    isLoading={saveCandidate.isLoading}
                                    isDisabled={saveCandidate.isLoading}
                                    loadingText={"Saving"}
                                >
                                    Save
                                </Button>
                                <Button variant="ghost" onClick={goBackToCandidate}>
                                    Cancel
                                </Button>
                            </Flex>
                        </VStack>
                    </fieldset>
                </form>
            )}
        </>
    );
};
